import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { TabNavigationWrapperStyled } from '@ui/tab-navigation/TabNavigation.styled';
import { transSlots } from '@legacyApp/client/modules/translation/translate';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { SectionsTabNav } from '@common/components/home/SectionsTabNav/SectionsTabNav';
import { SectionsTabNavStyled } from '@common/components/home/SectionsTabNav/SectionTabNav.styled';
import { Loader } from '@legacyApp/client/components/loader/loader';
import { DIRECTION } from '@legacyApp/client/modules/app/sortService';
import { useFetchGames } from '../hooks/useFetchGames';
import { StyledListWrapper } from '../components/ListWrapper.styled';
import {
	ProvidersSection,
	StyledProvidersSection,
} from '../components/ProvidersSection';
import { Slot, SlotCategoryDTO } from '../store/slots.types';
import { TileSlotsGrid } from '../components/TileSlotsGrid';
import { NoGames } from '../components/NoGames';
import { Option, SlotsFilters } from '../components/SlotsFilters';
import { ShowMore } from '../components/ShowMore';
import {
	DEFAULT_GAMES_PAGE,
	GAMES_ON_PAGE,
} from '../constants/SlotMenu.constants';
import { SlotsBreadcrumbs } from '../components/SlotsBreadcrumbs';
import { SlotCategoryBanner } from '../components/SlotCategoryBanner';

export const StyledSlotContainer = styled.div`
	display: flex;
	flex-direction: column;

	${SectionsTabNavStyled} {
		margin-top: 10px;
	}

	${TabNavigationWrapperStyled} {
		margin-bottom: -40px;
	}

	${StyledProvidersSection} {
		margin-top: 80px;
	}
`;

export const StyledSectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		flex-direction: column;
	}
`;

export const StyledHeaderTitleContainer = styled.div`
	align-items: center;
	display: flex;
`;

export const StyledHeaderFilters = styled.div`
	display: flex;
`;

export const SORT_OPTION: Option[] = [
	{ label: 'Popular', value: undefined },
	// { label: 'Featured', value: 'Featured' },
	{ label: 'A-Z', value: DIRECTION.ASC.toUpperCase() },
	{ label: 'Z-A', value: DIRECTION.DESC.toUpperCase() },
];

export const SlotCategoryLayout: FC<{ category: SlotCategoryDTO }> = ({
	category,
}) => {
	const categoryId = category?.id;
	const categoryName = category?.name;

	const [filterProviders, setFilterProviders] = useState([]);
	const [filterSort, setFilterSort] = useState(SORT_OPTION[0]);
	const [gamesCount, setGamesCount] = useState(GAMES_ON_PAGE);
	const memoizedSetGamesCount = useCallback(
		(count) => setGamesCount(count),
		[],
	);
	const [gamesPage, setGamesPage] = useState(DEFAULT_GAMES_PAGE);
	const [dataKey, setDataKey] = useState('');

	const { callback } = useFetchGames();
	const gamesCallback = useCallback(
		() =>
			callback({
				count: gamesCount,
				page: gamesPage,
				categoryId: !categoryId ? null : categoryId,
				...(filterProviders && { provider: filterProviders }),
				...(filterSort.value && { orderBy: filterSort.value }),
			}),
		[
			gamesCount,
			callback,
			categoryId,
			filterProviders,
			filterSort.value,
			gamesPage,
		],
	);
	const {
		data: list,
		loaded,
		count,
		loading,
	} = useGetDataArray<Slot>(`${dataKey}${gamesPage}`, gamesCallback);

	const showMoreHandler = () => {
		setGamesPage((prevGamesPage) => prevGamesPage + 1);
	};

	const setFilterProviderHandler = (provider, add) => {
		setFilterProviders((previousFilterProviders) => {
			if (add) {
				return [...previousFilterProviders, provider];
			}
			return previousFilterProviders.filter((item) => item !== provider);
		});
	};

	const setFilterSortHandler = (sort) => {
		setFilterSort(sort);
	};

	useEffect(() => {
		const dataKey_ = `${categoryName},${gamesCount},${filterProviders.join(
			',',
		)},${filterSort.value}`;
		if (dataKey_ !== dataKey) {
			setGamesPage(DEFAULT_GAMES_PAGE);
			setDataKey(dataKey_);
		}
	}, [
		categoryId,
		categoryName,
		dataKey,
		filterProviders,
		filterSort.value,
		gamesCount,
	]);

	return (
		<StyledSlotContainer>
			<SlotCategoryBanner category={category} />
			<SectionsTabNav activeTabId={category.slug} />
			<StyledSectionHeader>
				<StyledHeaderTitleContainer>
					<SlotsBreadcrumbs title={transSlots({ label: categoryName })} />
				</StyledHeaderTitleContainer>
				<StyledHeaderFilters>
					<SlotsFilters
						name={categoryName}
						filterSort={filterSort}
						filterProviders={filterProviders}
						setFilterProvider={setFilterProviderHandler}
						setFilterSort={setFilterSortHandler}
					/>
				</StyledHeaderFilters>
			</StyledSectionHeader>
			<StyledListWrapper>
				<Loader active={loading} />
				{list?.length === 0 && loaded && !loading && <NoGames />}
				<TileSlotsGrid
					setGamesCount={memoizedSetGamesCount}
					items={list}
					loaded={loaded}
				/>
				{!!loaded && list.length > 0 && (
					<ShowMore
						displayingGames={list.length}
						allGames={count}
						showMore={showMoreHandler}
					/>
				)}
			</StyledListWrapper>
			<ProvidersSection />
		</StyledSlotContainer>
	);
};
