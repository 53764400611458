import React, { FC, MouseEvent } from 'react';
import { TabNavigationProps } from '@ui/tab-navigation';
import { useDispatch } from 'react-redux';
import { Lobby } from '@icons/lobby';
import { InHouse } from '@icons/in-house';
import { FavoriteIconV2 } from '@icons/slots/favorite_v2';
import { NewGames } from '@icons/new-games';
import {
	Trans,
	transSlots,
} from '@legacyApp/client/modules/translation/translate';
import { useMenuCategories } from '@modules/slots/components/SlotsMenu';
import { generateUrl, ROUTE } from '@common/routes';
import { generateSlotsHomeUrl } from '@modules/slots/services/Slots.service';
import { appGoTo } from '@legacyApp/client/store/app/app.actions';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { Icon } from '../../icons';
import { TabNavigation } from '../../TabNavigation';
import { Inhouse as InHouseContent } from './Inhouse';
import { Lobby as LobbyContent } from './Lobby';
import { GamesSearchBar } from './GamesSearchBar';
import { SectionsTabNavStyled } from './SectionTabNav.styled';

export const SectionsTabNav: FC<{
	activeTabId?: string;
	defaultTabId?: string;
}> = ({ activeTabId, defaultTabId }) => {
	const isLogged = useUserIsLogged();
	const menuCategories = useMenuCategories();
	const dispatch = useDispatch();

	const onClickIndexPaths: TabNavigationProps['onClick'] = (
		event: MouseEvent,
		{ id }: { id: string },
	) => {
		dispatch(appGoTo(generateSlotsHomeUrl(id ? { tab: id } : undefined), true));
	};

	const onClickRoutePaths = (route: ROUTE, category?: string) =>
		dispatch(
			appGoTo(
				generateUrl(route, {
					category,
				}),
				true,
			),
		);

	const TABS: TabNavigationProps['tabs'] = [
		{
			id: 'lobby',
			label: Trans({ label: 'Lobby' }),
			icon: Lobby,
			content: LobbyContent,
			onClick: onClickIndexPaths,
		},
		isLogged && {
			id: 'favorite',
			label: transSlots({ label: 'My Favorite' }),
			icon: (props) => <FavoriteIconV2 {...props} />,
			onClick: () => onClickRoutePaths(ROUTE.slotFavorites),
		},
		{
			id: 'in-house',
			label: Trans({ label: 'In House' }),
			icon: InHouse,
			content: () => <InHouseContent noName />,
			onClick: onClickIndexPaths,
		},
		...menuCategories.map((category) => {
			return {
				id: category.slug,
				label: transSlots({ label: category.name }),
				icon: (props) => <Icon id={category.slug} props={props} />,
				onClick: () => onClickRoutePaths(ROUTE.slotCategoryPage, category.slug),
			};
		}),
		{
			id: 'new-games',
			label: transSlots({ label: 'New Games' }),
			icon: NewGames,
			onClick: () => onClickRoutePaths(ROUTE.slotCategoryPage, 'new-games'),
		},
	].filter((el) => !!el);

	return (
		<SectionsTabNavStyled>
			<GamesSearchBar />
			<TabNavigation
				tabs={TABS}
				activeTabId={activeTabId}
				defaultTabId={defaultTabId}
			/>
		</SectionsTabNavStyled>
	);
};
