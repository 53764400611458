import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { ScrollCardsWithTitle } from '@common/components/layout/ScrollCards/ScrollCardsWithTitle';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { useUserIsAdmin } from '@common/selectors/user.selectors';
import { useFetchProviders } from '../hooks/useFetchProviders';
import { ProviderDTO } from '../store/slots.types';
import { SLOTS_SCROLL_CARD_COUNT } from '../constants/Slots.constants';
import { ProviderTile } from './ProviderTile';

const StyledProvidersSection = styled.div`
	margin-bottom: 15px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		margin-bottom: 10px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		margin-bottom: 5px;
	}
`;

const StyledProviderTileWrapper = styled.div`
	margin-right: 20px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		margin-right: 15px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		margin-right: 10px;
	}
`;

interface ProvidersScrollCardsProps {
	name?: any;
}

export const ProvidersScrollCards: FC<ProvidersScrollCardsProps> = ({
	name,
}) => {
	const { callback, options } = useFetchProviders();
	const providersCallback = useCallback(
		() => callback(SLOTS_SCROLL_CARD_COUNT),
		[callback],
	);
	const userIsAdmin = useUserIsAdmin();
	const { data: providers_, count } = useGetDataArray<ProviderDTO>(
		`providers-${userIsAdmin}`,
		providersCallback,
		options,
	);

	return (
		<StyledProvidersSection>
			<ScrollCardsWithTitle
				name={name}
				count={count}
				href={'/slots/provider/all'}
				list={providers_.map((provider: ProviderDTO) => {
					return {
						name: provider.name,
						component: (
							<StyledProviderTileWrapper key={provider.id}>
								<ProviderTile
									slug={provider.slug}
									isNew={provider.new}
									name={provider.name}
								/>
							</StyledProviderTileWrapper>
						),
					};
				})}
			/>
		</StyledProvidersSection>
	);
};
