import React, { FC } from 'react';
import styled from 'styled-components';
import { ScrollCards } from '@common/components/layout/ScrollCards/ScrollCards';
import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';
import { LoaderListWrapper } from '@common/components/contentLoaders/LoaderListWrapper';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { useUserIsAdmin } from '@common/selectors/user.selectors';
import { useFetchProviders } from '../hooks/useFetchProviders';
import { ProviderDTO } from '../store/slots.types';
import { StyledLoaderListItemWrapper } from './LoaderListItemWrapper.styled';
import { StyledHorizontalListWrapper } from './HrizontalListWrapper.styled';
import { ProviderTile } from './ProviderTile';

const DEFAULT_COUNT = 6;

export const StyledProvidersSection = styled.div`
	margin-bottom: 16px;
	.menu-wrapper {
		width: 100%;
	}
	.menu-wrapper--inner {
		display: flex;
	}
	.scroll-menu-arrow {
		top: -44px !important;
	}
`;

const StyledProviderTileWrapper = styled.div`
	margin-right: 20px;
`;

export const ProvidersSection: FC = () => {
	const { callback, options } = useFetchProviders();
	const userIsAdmin = useUserIsAdmin();
	const { data: providers_, loaded } = useGetDataArray<ProviderDTO>(
		`providers-${userIsAdmin}`,
		callback,
		options,
	);
	// console.log('ProviderSection', { providers });
	return (
		<StyledProvidersSection>
			<LoaderListWrapper
				list={providers_}
				loaded={loaded}
				preloadElement={(el, index) => (
					<StyledLoaderListItemWrapper key={index}>
						<RectangleLoader id={index} />
					</StyledLoaderListItemWrapper>
				)}
				preloadLength={DEFAULT_COUNT}
				loaderWrapper={StyledHorizontalListWrapper}
				listElement={
					<StyledProvidersSection>
						<ScrollCards
							list={providers_.map((provider: ProviderDTO) => {
								return {
									name: provider.name,
									component: (
										<StyledProviderTileWrapper key={provider.id}>
											<ProviderTile
												slug={provider.slug}
												isNew={provider.new}
												name={provider.name}
											/>
										</StyledProviderTileWrapper>
									),
								};
							})}
						/>
					</StyledProvidersSection>
				}
			/>
		</StyledProvidersSection>
	);
};
